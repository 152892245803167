// import { defineStyleConfig } from "@chakra-ui/react";

const Button = {
  baseStyle: {
    fontWeight: 600,
    borderRadius: "lg",
  },
  sizes: {
    xs: {
      fontSize: 10,
      lineHeight: "4",
      px: 2,
      py: 2,
      minW: "80px",
      borderRadius: "md",
    },
    sm: {
      fontSize: 14,
      lineHeight: "5",
      px: 3,
      py: "10px",
      minW: "80px",
      borderRadius: "md",
    },
    md: {
      fontSize: 16,
      lineHeight: "6",
      px: 3,
      py: 3,
      minW: "128px",
    },
    lg: {
      fontSize: 16,
      lineHeight: "6",
      px: 6,
      py: 3,
      minW: "176px",
    },
    xl: {
      fontSize: 16,
      lineHeight: "6",
      px: 6,
      py: 4,
      minW: "210px",
    },
  },
  variants: {
    outline: {
      border: "1px solid",
      borderColor: "divider",
      color: "textPrimary",
      bg: "card",
      _hover: {
        bg: "accent",
        color: "white",
        _disabled: {
          bg: "divider",
          color: "textTertiary",
        },
      },
      _dark: {
        bg: "card",
        color: "primary",
        _hover: {
          bg: "accent",
          color: "white",
        },
      },
    },
    solid: {
      bg: "accent",
      color: "white",
      _hover: {
        bg: "accent",
        _disabled: {
          bg: "divider",
          color: "textTertiary",
        },
      },
      _disabled: {
        bg: "divider",
        color: "textTertiary",
      },
      _dark: {
        bg: "accent",
        color: "white",
        _disabled: {
          bg: "canvas",
          color: "textTertiary",
        },
      },
    },
  },
  defaultProps: {
    size: "md",
    variant: "solid",
  },
};

export default Button;
