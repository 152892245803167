import { extendTheme } from "@chakra-ui/react";

import styles from "./styles";
import semanticTokens from "./semanticTokens";
import textStyles from "./textStyles";
import components from "./components";

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const theme = extendTheme({
  styles,
  semanticTokens,
  textStyles,
  components,
  config,
});

export default theme;
