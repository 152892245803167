import type { AppProps } from "next/app";
import Head from "next/head";
import { Center, ChakraProvider } from "@chakra-ui/react";

import theme from "mlp-sdk/theme";

// Firebase Utils
import "mlp-sdk/utils/firebase";

import { RudderStackScript } from "app-head";

import "mlp-sdk/styles/styles.css";
import "react-loading-skeleton/dist/skeleton.css";
import { AppProvider } from "mlp-sdk/providers/AppProvider";

import AnalyticsMapping from "constants/analytics_mapping.json";

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>
          Liquid Staking - Best Crypto Staking Platform of 2023 | Stader Labs
        </title>
        <link rel="icon" href="/favicon.ico" />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <RudderStackScript fileName={AnalyticsMapping["fileName"]} />
      </Head>

      <ChakraProvider theme={theme}>
        <AppProvider>
          <Center flexDir="column">
            <Component {...pageProps} />
          </Center>
        </AppProvider>
      </ChakraProvider>
    </>
  );
}
