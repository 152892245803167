const textStyles = {
  h1: {
    fontSize: "40px",
    fontWeight: "700",
    lineHeight: "140%",
  },
  h2: {
    fontSize: "32",
    fontWeight: "700",
    lineHeight: "140%",
  },
  h3: {
    fontSize: "24",
    fontWeight: "700",
    lineHeight: "140%",
  },
  h4: {
    fontSize: "20",
    fontWeight: "700",
    lineHeight: "140%",
  },
  h5: {
    fontSize: "16",
    fontWeight: "600",
    lineHeight: "140%",
  },
  h6: {
    fontSize: "14",
    fontWeight: "600",
    lineHeight: "140%",
  },
  p16: {
    fontSize: "16",
    fontWeight: "400",
    lineHeight: "150%",
  },
  p14: {
    fontSize: "14",
    fontWeight: "400",
    lineHeight: "150%",
  },
  p12: {
    fontSize: "12",
    fontWeight: "400",
    lineHeight: "150%",
  },
  p10: {
    fontSize: "10",
    fontWeight: "400",
    lineHeight: "150%",
  },
  l14: {
    fontSize: "14",
    fontWeight: "700",
    lineHeight: "20px",
  },
  l12: {
    fontSize: "12",
    fontWeight: "700",
    lineHeight: "18px",
  },
  l10: {
    fontSize: "10",
    fontWeight: "700",
    lineHeight: "16px",
  },
};

export default textStyles
