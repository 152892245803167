// TODO: get it from ENV
export const firebaseClientConfig: any = {
  apiKey: "AIzaSyDsJVHQ4ARABoYPsN0IZDzG0q1qn8rAX3g",
  authDomain: "stader-landing-ui.firebaseapp.com",
  projectId: "stader-landing-ui",
  storageBucket: "stader-landing-ui.appspot.com",
  messagingSenderId: "559177656064",
  appId: "1:559177656064:web:bd0dfcfad94263c0a3b5e5",
  measurementId: "G-5XWXXWNPPD",
};
