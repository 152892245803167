import Button from "./Button";
import Container from "./Container";
import Link from "./Link";

const components = {
  Button,
  Container,
  Link,
};

export default components;
