const styles = {
  global: {
    "*": {
      boxSizing: "border-box",
      padding: 0,
      margin: 0,
    },
    "html, body": {
      maxWidth: "100vw",
      fontSize: 16,
      fontWeight: 400,
      fontFamily: "Gilroy, Helvetica, sans-serif, system-ui",
      backgroundColor: "white",
      color: "textPrimary",
    },
    "body::-webkit-scrollbar": {
      display: "none",
    },
    "div::-webkit-scrollbar": {
      display: "none",
    },
    "@keyframes fadeIn": {
      "0%": { opacity: 0 },
      "100%": { opacity: 1 },
    },
  },
};

export default styles;
