// import { defineStyleConfig } from "@chakra-ui/react";

const Link = {
  baseStyle: {
    fontWeight: 700,
    fontSize: 14,
    color: "primary",
    textDecor: "none !important",
  },

  variants: {
    secondary: {
      color: "white",
      borderBottom: "1px dashed",
      borderColor: "primary",
      _dark: {
        color: "accent",
        borderColor: "accent",
      },
    },
    primary: {
      _dark: {
        color: "white",
      },
    },
  },
  defaultProps: {
    size: "md",
    variant: "primary",
  },
};

export default Link;
