// import { defineStyleConfig } from "@chakra-ui/react";

const Container = {
  baseStyle: {
    maxW: "1224px",
    borderRadius: 2,
    textAlign: "left",
    py: 0,
    px: { base: 2, lg: 4 },
  },
};

export default Container;
