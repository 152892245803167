import { createContext, useState } from "react";

const SUB_ROUTE = process.env.NEXT_PUBLIC_SUB_ROUTE;

interface AppContextProps {
  tagQuery: any;
  setTagQuery: (q: any) => void;
}

export const AppContext = createContext<AppContextProps>({
  tagQuery: null,
  setTagQuery: () => {},
});

export const AppProvider = ({ children }: any) => {
  const [tagQuery, setTagQuery] = useState<any>(
    SUB_ROUTE === "/blogs" ? { tag: "all", page: 1 } : null
  );

  return (
    <AppContext.Provider value={{ tagQuery, setTagQuery }}>
      {children}
    </AppContext.Provider>
  );
};
