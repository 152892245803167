const semanticTokens = {
  colors: {
    primary: "#07C166",
    accent: "#07A658",
    canvas: "#F5F5F7",
    // canvas: {
    //   default: "#F5F5F7",
    //   _dark: "#142528",
    // },
    card: "#FFFFFF",
    // card: {
    //   default: "#FFFFFF",
    //   _dark: "#1B3034",
    // },
    divider: "#AEDFCA",
    // divider: {
    //   default: "#AEDFCA",
    //   _dark: "#424242",
    // },
    overlay: "#14252880",
    // overlay: {
    //   default: "#14252880",
    //   _dark: "#142528CC",
    // },
    textPrimary: "#142528",
    // textPrimary: {
    //   default: "#142528",
    //   _dark: "#FFFFFF",
    // },
    textSecondary: "#3F6870",
    // textSecondary: {
    //   default: "#3F6870",
    //   _dark: "#A1B6BA",
    // },
    textTertiary: "#A1B6BA",
    // textTertiary: {
    //   default: "#A1B6BA",
    //   _dark: "#5D6D71",
    // },
    success: "#07A658",
    warning: "#FF8C00",
    error: "#FF4D4D",
    notification: "#2196F3",
    polygon: "#6F30E8",
    fantom: "#186AFF",
    near: "#2FA8AF",
    ethereum: "#8D66B4",
    binance: "#F3BA2F",
    sd: "#07C166",
    hedera: "#072216",
    terra: "#5393F7",
    terraClassic: "#FFD83D",
    aurora: "#6FD24D",
    aptos: "#005599",
  },
};

export default semanticTokens;
